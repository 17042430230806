.App {
  text-align: center;
}

.App-header {
  align-items: center;
  background-position: 50%;
  background-size: cover;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  font-weight: 300;
  justify-content: center;
  min-height: 100vh;
}

.textually-titular {
  font-size: calc(14px + 1vmin);
  font-weight: 300;
}

.figmaEmbed {
  border-radius: 30px;
  box-shadow: 5px 9px 15px rgb(0,0,0,0.5);
}